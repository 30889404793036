import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../scss/ProductDetailsPage.css';
import parse from 'html-react-parser'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'font-awesome/css/font-awesome.min.css';
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import { getCustomerFeedbackData, getRatingChartData } from '../../../Redux/Action/Action';
import { Rating } from 'react-simple-star-rating';
import { createImageFromInitials } from '../.././Utils';
const host = process.env.REACT_APP_API_SERVER;

const ProductDetailsPageContent = (productData) => {


  const [isReviewFormOpen, SetIsReviewFormOpen] = useState(false);



  function openReviewForm() {
    SetIsReviewFormOpen(true);
  }

  const data = productData?.productData;

  const imgData = data?.productImg

  // specification
  let specs = [];
  if (data?.specification) {
    try {
      specs = JSON.parse(data.specification);
    } catch (error) {
      console.error("Failed to parse specifications:", error);

    }
  }
  let modelNumber = 'TRANO 850S 12V SOLAR UPS';
  let capacity = "43kW";

  const modelTitles = ["Model No.", "Model number", "Model Number"];
  const modelSpec = specs.find(spec => modelTitles.includes(spec.title));
  modelNumber = modelSpec ? modelSpec.description : "";

  // For Capacity
  const capacityTitles = ["capacity", "Capacity"];
  const capacitySpec = specs.find(spec => capacityTitles.includes(spec.title));
  capacity = capacitySpec ? capacitySpec.description : "";



  const createListItemsFromHTML = (htmlString) => {
    // Handle <br>, <br />, <li>, and <p> elements
    const cleanedHtmlString = htmlString
      .replace(/<\/li>/g, '<br>')   // Replace closing </li> tags with <br> for list items
      .replace(/&bull;/g, '')      // Replace &bull; with bullet point (•) symbol
      .replace(/&nbsp;/g, ' ')     // Replace &nbsp; with a space


    return cleanedHtmlString.split(/<br\s*\/?>/i).map((item, index) => {
      // Remove any remaining HTML tags and trim the string
      const cleanItem = item.replace(/<[^>]+>/g, '').trim();

      // Only return non-empty list items
      if (cleanItem) {
        return <li key={index}>{cleanItem}</li>;
      }
      return null;
    }).filter(item => item !== null); // Filter out null items
  };



  //  salientFeatures 
  const listItems = data?.salientFeatures ? createListItemsFromHTML(data.salientFeatures) : [
    <li key="default"></li>
  ];

  const uspItems = data?.usp ? createListItemsFromHTML(data.usp) : [
    <li key="default"></li>
  ];



  // console.log('listItems', listItems)
  // console.log('uspItems', uspItems)

  function downloadFile(data, filename, type) {
    const file = new Blob([data], { type: type });
    const a = document.createElement("a");
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  // Function to convert JSON to CSV
  function jsonToCSV(jsonData) {
    const data = JSON.parse(jsonData);
    const csvRows = [];

    // Get headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    // Loop over the rows
    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  // Example usage
  // const jsonData = '[{"title":"asdfgh","description":"asdfgh"},{"title":"Model No.","description":"23454"},{"title":"Model No.sdfghjk","description":"REN P1P150012SOLAR"},{"title":"Model No.sdfghjk","description":"REN P1P150012SOLAR"},{"title":"Capacity","description":"23kw"}]';
  // const csvData = jsonToCSV(jsonData);


  // const handleClick = () => {
  //   downloadFile(csvData, 'specification.csv', 'text/csv');
  // }


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerFeedbackData(data?.id));
    dispatch(getRatingChartData(data?.id));

  }, [data?.id]);

  const CustomerFeedbackData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.CustomerFeedbackDataList?.data) : []);
  const RatingChartData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.RatingChartDataList?.data) : []);
  const rate = Math.floor(RatingChartData?.averageRatingOutOf5)

  const totalStars = 5;



  const [srating, setsRating] = useState(0)
  const handleRating = (rate) => {
    setsRating(rate)
  }

  const [rating, setRating] = useState(0)
  const handleRatings = (rate) => {
    setRating(rate)
  }

  const id = data?.id;
  let [initialValues, setInitialValues] = useState({
    name: "",
    review: "",
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required").matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
      review: Yup.string().required("Review is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {

      try {

        setSubmitting(true);


        document.body.style.overflow = 'hidden';
        const value = {
          name: values.name,
          review: values.review,
          rating: srating,
          productId: id,
        }

        await axios.post(`${host}/web/customer-feedback`, value)
          .then(function (response) {
            if (response.status == 200) {
              setSubmitting(false)
              document.body.style.overflow = 'auto';
              toast.success("Form Submitted Succesfully");
              dispatch(getCustomerFeedbackData(data?.id));
              dispatch(getRatingChartData(data?.id));
              resetForm();
              SetIsReviewFormOpen(false)
              setsRating(0)
            } else {

              setSubmitting(false)
              document.body.style.overflow = 'auto';
              toast.error(response.data.msg);
              resetForm();
              SetIsReviewFormOpen(false)
              setsRating(0)
            }
          })
          .catch(function (error) {

            document.body.style.overflow = 'auto';
            setSubmitting(false)
            toast.error('Error submitting form. Please try again.');
            resetForm();
            SetIsReviewFormOpen(false)
            setsRating(0)
          })

      }
      catch (error) {

        document.body.style.overflow = 'auto';
        setSubmitting(false)
        toast.error('Error submitting form. Please try again.');
        resetForm();
        SetIsReviewFormOpen(false)
        setsRating(0)
      }

    }
  })

  const [displayLimit, setDisplayLimit] = useState(5);


  const loadMore = () => {
    setDisplayLimit((prevLimit) => prevLimit + 5);
  };

  // const [nav1, setNav1] = useState(null);
  // const [nav2, setNav2] = useState(null);
  // let sliderRef1 = useRef(null);
  // let sliderRef2 = useRef(null);

  // useEffect(() => {
  //   setNav1(sliderRef1);
  //   setNav2(sliderRef2);
  // }, []);

  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);


  const SliderFor = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // asNavFor: '.slider-nav',
  };
  const SliderNav = {
    slidesToShow: 4,
    slidesToScroll: 1,
    // asNavFor: '.slider-for',
    dots: false,
    autoplay: true,
    focusOnSelect: true,
    centerPadding: '0',
    // swipeToSlide: false,
    centerMode: true,
    autoplaySpeed: 2000,
    infinite: imgData?.length > 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* ========================================================================== */}
      <section className="product-details-page-banner-section">
        {data ? <div className="screen-sixteen-nine-four-sec">
          <div className="screen-sixteen-nine-four-img-sec">
            <img
              src="../images/product-details/background-graphic-one.png"
              className="img-fluid"
            />
          </div>
          {/* Start Screen-16:9-4 Section-1 */}
          <section className="screen-sixteen-nine-four-section-one">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-12 mb-0">
                  <div className="section-one-parent-one">
                    <div className="card">
                      {data && imgData?.length > 0 &&
                        <div className="card-body">

                          <Slider
                            {...SliderFor}
                            asNavFor={slider2}
                            ref={(slider) => (setSlider1(slider))}
                            className="slider-for img-sec"
                          >
                            {imgData?.map((data) => (
                              <div>
                                <img
                                  src={data?.image ? `${host}${data?.image}` : "../images/product-details/group-fifty.png"}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>))}



                          </Slider>
                          <div className="slider-sec px-5">
                            {data && imgData?.length > 1 && <Slider
                              {...SliderNav}
                              asNavFor={slider1}
                              ref={(slider) => (setSlider2(slider))}
                              className="row slider-nav"
                            >
                              {data && imgData?.length > 0 && imgData?.map((data) => (
                                <div className="col-xl-3 col-lg-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="img-sec">
                                        <img
                                          src={data?.image ? `${host}${data?.image}` : "../images/product-details/group-fifty-six.png"}
                                          className="img-fluid"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Slider>}
                          </div>
                        </div>}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-12 text-start">
                  <div className="section-one-parent-two">
                    <div className="section-one-parent-two-child py-4">
                      <div className="section-one-parent-two-sub-child py-4">
                        <div className="heading-sec">
                          <h2>{data?.ChildCategoryyy?.name ? data?.ChildCategoryyy?.name : ''}</h2>
                          <p>
                            <b>{data?.name ? data?.name : ""}</b>
                          </p>
                        </div>
                        <div className="scroll-height-fix-dv">
                          <div className="para-sec">
                            {data?.description ? parse(data?.description) : <p>

                            </p>}
                          </div>
                          <div className="keyfeatures-sec">
                            <p>
                              <b>Key Features:</b>
                            </p>
                            {data?.keyFeatures ? parse(data?.keyFeatures) : <ul>

                            </ul>}
                          </div>
                        </div>
                        <div className="btns">
                          <Link to="/contact-us" >
                            <button className="btn-one p-2 ">
                              <img
                                src="../images/product-details/group-fifty-two.png"
                                className="img-fluid"
                              />
                              &nbsp;&nbsp;Enquiry Now
                            </button>
                          </Link>
                          <Link to="/store-locator" >
                            <button className="btn-two p-2 ">
                              <img
                                src="../images/product-details/group-fifty-four.png"
                                className="img-fluid"
                              />
                              &nbsp;&nbsp;Store Locator
                            </button>
                          </Link>
                          {data?.specsFile && <a href={`${host}${data.specsFile}`} target='blank'
                            className='text-decoration-none btn-three d-flex  py-2'>

                            <div className="btn-three-img-sec">
                              <img
                                src="../images/product-details/specification-btn-icon.png"
                                className="img-fluid"
                              />
                            </div>
                            <span className='text-dark'>&nbsp;&nbsp;Download Specification</span>
                          </a>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Screen-16:9-4 Section-1 */}
          {/* Start Screen-16:9-4 Section-2 */}
          <div className="screen-sixteen-nine-four-section-two">
            <div className="section-one-parent-three py-5">
              <div className="container">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Salient Features
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      USP
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Specification
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="matter-list">
                      <div className="group-lists">
                        <div className="lists-blank">
                          <li className="blank">blank</li>
                        </div>
                        {listItems?.map((data) => (
                          <div className="lists">

                            {data?.props?.children ? parse(data?.props?.children) : ""}

                          </div>))}

                        <div className="lists-blank">
                          <li className="blank">blank</li>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="matter-list">
                      <div className="group-lists">
                        <div className="lists-blank">
                          <li className="blank">blank</li>
                        </div>
                        {uspItems?.map((data) => (
                          <div className="lists">

                            {data?.props?.children ? parse(data?.props?.children) : ''}

                          </div>))}

                        <div className="lists-blank">
                          <li className="blank">blank</li>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >

                    <table className="table">
                      <tbody>
                        <tr className=".t-heading py-5 bg-white">
                          <td scope="col"><span className='invisible'>hello</span></td>
                          <td scope="col"><span className='invisible'>hello</span></td>
                        </tr>
                        {specs ? specs?.map((data) => (
                          <tr>
                            <td scope="col">{data?.title}</td>
                            <td scope="col">{data?.description}</td>
                          </tr>))
                          :
                          ""}
                        <tr className=".t-heading bg-white">
                          <td scope="col"><span className='invisible'>hello</span></td>
                          <td scope="col"><span className='invisible'>hello</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="screen-sixteen-nine-four-section-two-img-sec">
                <img
                  src="../images/product-details/background-graphic-two.png"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          {/* End Screen-16:9-4 Section-2 */}
          {/* Start Screen-16:9-4 Section-3 */}
          <section className="screen-sixteen-nine-four-section-three">
            <div className="section-one-parent-four">
              <div className="container">
                {RatingChartData?.length > 1 && <><div className="heading mb-5">
                  <h5>What Our Customers Say</h5>
                </div><div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-12 mb-3">
                      <div className="section-one-parent-four-content-box-one">
                        <h6>Overall Rating</h6>
                        <h1>{RatingChartData?.averageRatingOutOf5}/5</h1>
                        <div className="icon-sec">
                          {/* Render filled stars */}
                          {Array.from({ length: rate }).map((_, index) => (
                            <i className="fa fa-star rated" />
                          ))}

                          {/* Render unfilled stars */}
                          {Array.from({ length: totalStars - rate }).map((_, index) => (
                            <i className="fa fa-star" />
                          ))}
                        </div>
                        <p className="mb-0">{RatingChartData?.totalRatings} reviews </p>
                      </div>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-9 col-12 mb-3">
                      <div className="section-one-parent-four-content-box-two py-2 px-4">
                        <h6>Rating Breakdown</h6>
                        <div className="progress-one d-flex mb-2">
                          <div className="number-five">
                            <p className="mb-0">5</p>
                          </div>
                          <div className="star-icon">
                            <i className="fa fa-star" />
                          </div>
                          <div className="progress rounded-0">
                            <div
                              className="progress-bar bg-success mb-0"
                              role="progressbar"
                              style={{ width: `${(RatingChartData?.stars?.[5] / RatingChartData?.totalRatings) * 100}%` }}
                              aria-valuenow={RatingChartData?.stars?.[5]}
                              aria-valuemin={0}
                              aria-valuemax={RatingChartData?.totalRatings} />
                          </div>
                          <div className="percent">
                            <p className="mb-0">{RatingChartData?.stars?.[5]}</p>
                          </div>
                        </div>
                        <div className="progress-two mb-2">
                          <div className="number-five">
                            <p className="mb-0">4</p>
                          </div>
                          <div className="star-icon">
                            <i className="fa fa-star" />
                          </div>
                          <div className="progress rounded-0">
                            <div
                              className="progress-bar bg-primary mb-0"
                              role="progressbar"
                              style={{ width: `${(RatingChartData?.stars?.[4] / RatingChartData?.totalRatings) * 100}%` }}
                              aria-valuenow={RatingChartData?.stars?.[4]}
                              aria-valuemin={0}
                              aria-valuemax={RatingChartData?.totalRatings} />
                          </div>
                          <div className="percent">
                            <p className="mb-0">{RatingChartData?.stars?.[4]}</p>
                          </div>
                        </div>
                        <div className="progress-three mb-2">
                          <div className="number-five">
                            <p className="mb-0">3</p>
                          </div>
                          <div className="star-icon">
                            <i className="fa fa-star" />
                          </div>
                          <div className="progress rounded-0">
                            <div
                              className="progress-bar bg-info mb-0"
                              role="progressbar"
                              style={{ width: `${(RatingChartData?.stars?.[3] / RatingChartData?.totalRatings) * 100}%` }}
                              aria-valuenow={RatingChartData?.stars?.[3]}
                              aria-valuemin={0}
                              aria-valuemax={RatingChartData?.totalRatings} />
                          </div>
                          <div className="percent">
                            <p className="mb-0">{RatingChartData?.stars?.[3]}</p>
                          </div>
                        </div>
                        <div className="progress-four mb-2">
                          <div className="number-five">
                            <p className="mb-0">2</p>
                          </div>
                          <div className="star-icon">
                            <i className="fa fa-star" />
                          </div>
                          <div className="progress rounded-0">
                            <div
                              className="progress-bar bg-warning mb-0"
                              role="progressbar"
                              style={{ width: `${(RatingChartData?.stars?.[2] / RatingChartData?.totalRatings) * 100}%` }}
                              aria-valuenow={RatingChartData?.stars?.[2]}
                              aria-valuemin={0}
                              aria-valuemax={RatingChartData?.totalRatings} />
                          </div>
                          <div className="percent">
                            <p className="mb-0">{RatingChartData?.stars?.[2]}</p>
                          </div>
                        </div>
                        <div className="progress-five mb-2">
                          <div className="number-five">
                            <p className="mb-0">1</p>
                          </div>
                          <div className="star-icon">
                            <i className="fa fa-star" />
                          </div>
                          <div className="progress rounded-0">
                            <div
                              className="progress-bar bg-danger mb-0"
                              role="progressbar"
                              style={{ width: `${(RatingChartData?.stars?.[1] / RatingChartData?.totalRatings) * 100}%` }}
                              aria-valuenow={RatingChartData?.stars?.[1]}
                              aria-valuemin={0}
                              aria-valuemax={RatingChartData?.totalRatings} />
                          </div>
                          <div className="percent">
                            <p className="mb-0">{RatingChartData?.stars?.[1]}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div></>}
                {!isReviewFormOpen && (
                  <button className="write-review-btn" onClick={openReviewForm}>Write a review</button>
                )}
              </div>
            </div>
          </section>
          {/* Start Screen-16:9-4 Section-3 */}
          {/* Start Screen-16:9-4 Section-4 */}
          <div className="screen-sixteen-nine-four-section-four">
            <div className="section-two-parent-one">

              <div className="heading-sec">
                <h5>Write a review</h5>
                <p>Rating</p>
              </div>

              <div className="section-two-child-sec">
                <div className="container">
                  <div className="section-two-child-one pb-5">
                    {isReviewFormOpen && (
                      <form className="row g-3 custom-row-form form-container" method='post' onSubmit={formik.handleSubmit} >
                        <FocusError formik={formik} />
                        <div className="star-icon">
                          <Rating
                            onClick={handleRating}


                          />
                        </div><div className="form-sec">
                          <div className="input-sec my-3">
                            <input
                              type="text"
                              placeholder="Your Name"
                              className="px-3"
                              name="name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                            />
                            <div className="help-block with-errors">
                              {formik.touched.name && formik.errors.name ? (
                                <div className='text-danger'>{formik.errors.name}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="textarea-sec mb-3">
                            <textarea
                              type="text"
                              placeholder="Write your comment here"
                              className="px-3"
                              defaultValue={''}
                              name="review"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.review}
                            />
                            <div className="help-block with-errors">
                              {formik.touched.review && formik.errors.review ? (
                                <div className='text-danger'>{formik.errors.review}</div>
                              ) : null}
                            </div>
                          </div>
                          <button className='submit-review-btn' type='submit'>Submit Review</button>
                        </div>
                      </form>
                    )}
                    {CustomerFeedbackData &&
                      <><div className="section-two-sub-child mb-5">

                        {CustomerFeedbackData?.slice(0, displayLimit).map((data) => (
                          <div className="section-two-sub-child-parent-one mb-3">
                            <div className="row g-0">
                              <div className="col-xl-2 col-lg-2 col-md-3 col-12">
                                <div className="section-two-sub-child-one">
                                  <img
                                    src={createImageFromInitials(500, `${data?.name}`)}
                                    className="img-fluid"
                                    style={{ borderRadius: "50%", width: "50%" }}
                                  />
                                </div>
                              </div>
                              <div className="col-xl-2 col-lg-2 col-md-3 col-12">
                                <div className="section-two-sub-child-two">
                                  <h5>{data?.name}</h5>
                                  <p className="para-one mb-0">
                                    <i>{new Date(data?.createdAt).toDateString()}</i>
                                  </p>

                                </div>
                              </div>
                              <div className="col-xl-8 col-lg-8 col-md-6 col-12">
                                <div className="section-two-sub-child-three">
                                  <div className="star-icon">
                                    {/* Render filled stars */}
                                    {Array.from({ length: data?.rating }).map((_, index) => (
                                      <i className="fa fa-star rated" />
                                    ))}

                                    {/* Render unfilled stars */}
                                    {Array.from({ length: totalStars - data?.rating }).map((_, index) => (
                                      <i className="fa fa-star" />
                                    ))}
                                  </div>

                                  <div className="para">
                                    <p>
                                      {data?.review}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      </div><div className="show-more-btn">
                          {CustomerFeedbackData && CustomerFeedbackData?.length > displayLimit ?
                            <button className="btn px-4">
                              <a onClick={loadMore}>Show More</a>
                            </button>
                            :
                            ""
                          }
                        </div></>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* End Screen-16:9-4 Section-4 */}
        </div >
          :
          ""}
      </section >
      {/* ========================================================================== */}
    </>
  );
};

export default ProductDetailsPageContent;
