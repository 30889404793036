import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./ServiceCenterLocatorPageBanner.css"
import { getServiceCenterLocatorData } from '../../../Redux/Action/Action';
import ServiceCenterLocatorPageContent from '../ServiceCenterLocatorPageContent/ServiceCenterLocatorPageContent';
const host = process.env.REACT_APP_API_SERVER;
const ServiceCenterLocatorPageBanner = (bannerData) => {

    const dispatch = useDispatch();
    const [searchTerm, setsearchTerm] = useState("");

    const handleSearchChange = (e) => {
        setsearchTerm(e.target.value);

    };

    const handleOnClick = () => {
        dispatch(getServiceCenterLocatorData(searchTerm));
    }

    useEffect(() => {
        if (searchTerm == "") {
            dispatch(getServiceCenterLocatorData(""));
        }
    }, [searchTerm])

    const serviceCenterLocatorData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.serviceCenterLocatorDataList?.data) : []);

    return (
        <>
            <section className='service-center-locator-banner-section' style={{ backgroundImage: `url(${host}${bannerData?.bannerData?.storeLocatorBanner ? bannerData?.bannerData?.storeLocatorBanner : "../../../../public/images/store-locator/banner-bg.png"})` }}>
                <div className="container">
                    <div className="row">
                        {bannerData?.bannerData?.serviceCenterLocatorHeading &&
                            <div className="col-md-12">
                                <div className="store-heading">
                                    <img src="./images/store-locator/heading-bg.png" alt="" />
                                    <h5>{bannerData?.bannerData?.serviceCenterLocatorHeading}</h5>
                                </div>
                            </div>}
                        <div className="col-md-12">
                            <div className="banner-img-dv">
                                <img src={bannerData?.bannerData?.serviceCenterLocatorImg ? `${host}/${bannerData?.bannerData?.serviceCenterLocatorImg}` : ""} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="banner-dv-search">

                                <div className="banner-search-parent">
                                    <input type="text" placeholder='Enter State Or Pincode Or City' onChange={handleSearchChange} />
                                    <button className='banner-search-btn mt-lg-0 mt-md-0 mt-3' onClick={handleOnClick}>Search</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServiceCenterLocatorPageContent serviceCenterLocatorData={serviceCenterLocatorData} />
        </>
    )
}

export default ServiceCenterLocatorPageBanner